export enum DataMsgType {
  TO_DEVICE = 1,
  FROM_DEVICE = 2,
}

export enum BrokerCommand {
  ECHO = "echo",
  CONNECT = "connect_device",
}

export enum DeviceCommand {
  CON = "connect-heh",
  GET_DATA_CHANNEL = "data_channel",
  GET_DEVICE_INFO = "device_info",
  APPLY_FOTA = "apply_fota",
  REBOOT = "reboot",
  LS = "ls",
  ENROLL = "enroll",
  UNENROLL = "unenroll",
  NETCFG = "netcfg",
  WLANSCAN = "wlanscan",
  LIGHT = "light",
  SOUND = "sound",
  RECOVERY = "recovery",
}

interface JsonObject {}

export interface ICmdMsg extends JsonObject {
  src: string
  dest: string
  req_id: string
}

export interface IBrokerRequest extends ICmdMsg {
  cmd: BrokerCommand
}

export interface IBrokerResponse extends ICmdMsg {
  status: number // http status
  msg?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  data?: any // depends on which request the response is based on
}
export interface IBrokerConnectedResposne extends IBrokerResponse {
  status: number // http status
  msg?: string
  data: { id: string; cmd: string; data: string } // channel suffixes
}

export interface IDeviceServiceRequest extends ICmdMsg {
  cmd: DeviceCommand
  args?: string
}

export interface IDeviceServiceResponse extends ICmdMsg {
  status: number // http status
  msg?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  data?: any // depends on which request the response is based on
}

export interface IDataMsg extends JsonObject {
  a: DataMsgType
  d: string // base64
}

export const RELAY_SERVER = `wss://ws-rds.minut.com`
const BROKER_CHANNEL = "p2tool-broker"
export const BROKER_URI = `${RELAY_SERVER}/${BROKER_CHANNEL}`
export const BROKER_ID = "p2wss"

export function parseProtobufMsg(event: MessageEvent<string>) {
  const msg: IDataMsg = JSON.parse(event.data)
  return msg
}

export function parseDeviceCmdResponse(event: MessageEvent<string>) {
  const msg: IDeviceServiceResponse = JSON.parse(event.data)
  return msg
}

export function parseBrokerCmdResponse(event: MessageEvent<string>) {
  const msg: IBrokerResponse = JSON.parse(event.data)
  return msg
}
