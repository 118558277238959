import { useCallback, useEffect, useState } from "react"
import useWebSocket from "react-use-websocket"

import { useTimeout } from "usehooks-ts"

import { writeBinaryToStream } from "src/components/Install/webSerialUtil"
import {
  BROKER_ID,
  BROKER_URI,
  BrokerCommand,
  DataMsgType,
  DeviceCommand,
  IBrokerConnectedResposne as IBrokerConnectedResponse,
  IBrokerRequest,
  IBrokerResponse,
  IDataMsg,
  IDeviceServiceRequest,
  IDeviceServiceResponse,
  parseBrokerCmdResponse,
  parseDeviceCmdResponse,
  parseProtobufMsg,
  RELAY_SERVER,
} from "src/components/RemoteDebugService/minutWsProtocol"
import { useSerial } from "src/components/RemoteDebugService/useSerial"
import {
  arrayBufferToBase64,
  base64ToArrayBuffer,
} from "src/components/RemoteDebugService/websocketUtil"
import { Logger } from "src/utils/logger"

// Setup loggers
const brokerDebug = new Logger({ prefix: "[DEBUG RemoteDeviceDevTool]:" })
const deviceCommandDebug = new Logger({ prefix: "[DEBUG DeviceData]:" })
const deviceDataDebug = new Logger({ prefix: "[DEBUG DeviceData]:" })

type TBrokerRequestState = "connected" | "disconnected" | "connecting"

export function useBrokerWs({
  uid,
  onMessage,
  onBrokerConnect,
  connect,
}: {
  uid: string
  onMessage: (r: IBrokerResponse) => void
  onBrokerConnect?: (r: IBrokerConnectedResponse["data"]) => void
  connect: boolean
}) {
  const [brokerReqState, setBrokerReqState] =
    useState<TBrokerRequestState>("disconnected")
  const [brokerReqId, setBrokerReqId] = useState("")

  const { sendJsonMessage, readyState, getWebSocket } =
    useWebSocket<IBrokerRequest>(
      BROKER_URI,
      {
        // All instances is useBrokerWs will share the same websocket:
        // share: true,
        // Will attempt to reconnect on all close events, such as server shutting down:
        shouldReconnect: (_closeEvent) => true,
        onMessage(event) {
          const response = parseBrokerCmdResponse(event)
          if (response.req_id === brokerReqId && response.status === 200) {
            const r = response as IBrokerConnectedResponse
            setBrokerReqState("connected")
            setBrokerReqId("")
            onBrokerConnect?.(r.data) // Maybe this should be required?
          }
          onMessage(response)
        },
      },
      connect
    )

  useEffect(() => {
    function connectToBroker() {
      // We are not handling request errors/timeouts; this is an improvement
      // that should be done in the future
      brokerDebug.log("[Connecting to broker]")
      setBrokerReqState("connecting")
      const reqId = `${uid}-connecting`
      setBrokerReqId(reqId)
      const cmd = BrokerCommand.CONNECT
      const msg: IBrokerRequest = {
        src: uid,
        dest: BROKER_ID,
        cmd,
        req_id: reqId,
      }
      return sendJsonMessage(msg)
    }
    if (connect) {
      connectToBroker()
    }
  }, [connect, sendJsonMessage, uid])

  useEffect(() => {
    if (!connect) setBrokerReqState("disconnected")
  }, [connect])

  return { sendJsonMessage, readyState, brokerReqState, getWebSocket }
}

export function useDeviceCommand({
  uri,
  uid,
  onMessage,
  onError,
  onDeviceDataUri,
}: {
  uid: string
  uri: string
  onMessage: (r: IDeviceServiceResponse) => void
  onError?: (r: IDeviceServiceResponse | string) => void
  onDeviceDataUri: (s: string) => void
}) {
  const [reqId, setReqId] = useState<DeviceCommand | null>(null)
  const [deviceDataUri, setDeviceDataUri] = useState("")

  const { sendJsonMessage } = useWebSocket<IDeviceServiceRequest>(
    uri,
    {
      share: true,
      shouldReconnect: (_closeEvent) => true,
      onMessage(event) {
        const response = parseDeviceCmdResponse(event)
        deviceCommandDebug.log(response)
        if (response.dest !== uid) {
          // Ignore responses not addressed to us
          return
        }
        if (response.status !== 200) {
          deviceCommandDebug.error("Request failed", response)
          setReqId(null)
          onError?.(response)
          return
        }
        if (response.req_id === DeviceCommand.GET_DATA_CHANNEL) {
          setDeviceDataUri(response.data)
          onDeviceDataUri(response.data)
          onMessage(response)
          setReqId(null)
          return
        }
        if (response.req_id === reqId) {
          onMessage(response)
          setReqId(null)
          return
        }

        setReqId(null)
        onMessage(response)
      },
    },
    !!uri
  )

  function sendRequest(cmd: DeviceCommand, args?: string): void {
    setReqId(cmd)

    switch (cmd) {
      case DeviceCommand.LIGHT:
      case DeviceCommand.APPLY_FOTA: {
        const msg: IDeviceServiceRequest = {
          src: uid,
          dest: uri,
          req_id: cmd, // for now
          cmd,
          args,
        }
        return sendJsonMessage(msg)
      }

      default: {
        const msg: IDeviceServiceRequest = {
          src: uid,
          dest: uri,
          req_id: cmd, // for now
          cmd,
        }
        return sendJsonMessage(msg)
      }
    }
  }

  useTimeout(
    () => {
      onError?.(`[Request ${reqId} timed out]`)
      setReqId(null)
    },
    reqId ? 4000 : null
  )

  return { sendRequest, deviceDataUri, reqId }
}

export function useDeviceDataTunnel({
  uri,
  uid,
  onMessage,
}: {
  uid: string
  uri: string
  onMessage?: (r: IDataMsg) => void
}) {
  const {
    subscribe: serialSubscribe,
    isConnected,
    writableStream,
  } = useSerial()
  const [subscribing, setSubscribing] = useState(false)

  const { sendJsonMessage } = useWebSocket<IDataMsg>(
    uri,
    {
      shouldReconnect: (_closeEvent) => true,
      onMessage(event) {
        const response = parseProtobufMsg(event)
        handleBinary(response)
        onMessage?.(response)
      },
    },
    !!uri
  )

  function handleBinary(msg: IDataMsg) {
    switch (msg.a) {
      case DataMsgType.TO_DEVICE: {
        onBinaryData(base64ToArrayBuffer(msg.d))
        return
      }
      default:
        return // noop
    }
  }

  function onBinaryData(d: ArrayBuffer) {
    if (writableStream) {
      writeBinaryToStream(writableStream, d)
    }
  }

  function sendRequest(cmd: DeviceCommand, args?: string): void {
    switch (cmd) {
      case DeviceCommand.APPLY_FOTA: {
        const msg: IDeviceServiceRequest = {
          src: uid,
          dest: uri,
          req_id: cmd, // for now
          cmd,
          args,
        }
        return sendJsonMessage(msg)
      }

      case DeviceCommand.GET_DEVICE_INFO:
      default: {
        const msg: IDeviceServiceRequest = {
          src: uid,
          dest: uri,
          req_id: cmd, // for now
          cmd,
        }
        return sendJsonMessage(msg)
      }
    }
  }

  // Establish Device -> Websocket tunnel
  const addWebserialWebsocketTunnel = useCallback(() => {
    deviceDataDebug.log("adding ttyACM -> ws tunnel")
    serialSubscribe("", (serialMsg) => {
      const value = serialMsg.value
      const d = arrayBufferToBase64(value)
      sendJsonMessage({ a: DataMsgType.FROM_DEVICE, d })
    })
  }, [sendJsonMessage, serialSubscribe])

  useEffect(() => {
    if (isConnected && !subscribing) {
      addWebserialWebsocketTunnel()
      setSubscribing(true)
    }
  }, [addWebserialWebsocketTunnel, isConnected, subscribing])

  return { sendRequest }
}

const announceLog = new Logger({ prefix: "[DEBUG AnnounceWs]:" })

export function useAnnounceWs({
  chatUri = RELAY_SERVER,
  channel = "announce",
  connect,
  onMessage,
}: {
  chatUri?: string
  channel?: string
  connect: boolean
  onMessage?: (msg: string) => void
}) {
  const { sendJsonMessage } = useWebSocket(
    `${chatUri}/${channel}`,
    {
      share: true,
      onOpen: () => announceLog.log(`[You are connected to ${channel}]`),
      onClose: () => announceLog.log(`[You are disconnected from ${channel}]`),
      shouldReconnect: (_closeEvent) => true,
      onMessage(event) {
        const message = event.data
        announceLog.log(message)
        onMessage?.(message)
      },
    },
    connect
  )

  return { sendAnnounce: sendJsonMessage }
}
