import { useEffect } from "react"

import { useSerial } from "src/components/RemoteDebugService/useSerial"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"

export function SerialDeviceConnectButton({
  onConnected,
  onDisconnected,
  loading,
}: {
  onConnected?: () => void
  onDisconnected?: () => void
  loading?: boolean
}) {
  const { isConnected, connect, disconnect, portState } = useSerial()

  useEffect(() => {
    if (isConnected) onConnected?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected])

  const ConnectButton = () => {
    switch (portState) {
      case "closed":
      case "opening":
        return (
          <MButtonLegacy
            loading={portState === "opening" || loading}
            onClick={connect}
          >
            Connect to device
          </MButtonLegacy>
        )

      case "open":
      case "closing":
        return (
          <MButtonLegacy
            loading={portState === "closing" || loading}
            onClick={() => {
              disconnect()
              onDisconnected?.()
            }}
            emergency
          >
            Disconnect device
          </MButtonLegacy>
        )
    }
  }

  if (!navigator.serial) {
    return <MButtonLegacy disabled>Not available</MButtonLegacy>
  }

  return <ConnectButton />
}
