export function base64ToArrayBuffer(base64str: string): ArrayBuffer {
  return Uint8Array.from(window.atob(base64str), (c) => c.charCodeAt(0)).buffer
}

export function arrayBufferToBase64(buffer: Uint8Array) {
  let binary = ""
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    // @ts-expect-error: noUncheckedIndexedAccess
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
